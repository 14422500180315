/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Checkbox,
  TextField,
  Button,
  Dialog,
  Grid,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import { BsCloudUpload } from 'react-icons/bs';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import SavingChangesDialog from '../../../Components/BasicComponents/SavingChangesDialog';

import SchemeService, { ReturnedSchemeData, V2Scheme } from '../../../Services/SchemeService';
import BusinessService from '../../../Services/BusinessService';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '25px',
  paddingBottom: '10px',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledUploadToggle = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isUploading',
}) <{ isUploading?: boolean }>(({ theme, isUploading }) => ({
  color: theme.palette.common.white,
  backgroundColor: isUploading ? theme.palette.warning.main : theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: isUploading ? theme.palette.warning.main : theme.palette.secondary.main,
  },
})) as typeof Button;

const StyledCancel = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

const StyledImage = styled('img')(({ theme }) => ({
  width: '60%',
  boxShadow: '10px 10px 10px rgba(0,0,0,0.6)',
  marginBottom: '15px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    boxShadow: '5px 5px 5px rgba(0,0,0,0.4)',
  },
}));

type Props = {
  businessData: any,
  schemeData: ReturnedSchemeData | undefined,
  isCreate: boolean,
  open: boolean,
  partnerId: string,
  setOpen: (toggle: boolean) => void;
  schemeId: string,
  loadSchemeDetails: (id:string) => void;
};

type LocationAndAddress = {
  id: string,
  address: string,
  selected: boolean,
};

function SchemeEdit(props: Props) {
  const {
    businessData,
    schemeData,
    isCreate,
    open,
    partnerId,
    setOpen,
    schemeId,
    loadSchemeDetails,
  } = props;

  const [addStamp, setAddStamp] = useState<boolean>(false);
  const [images, setImages] = useState<string[]>([]);
  const [maxStamps, setMaxStamps] = useState<string>('');
  const [locations, setLocations] = useState<LocationAndAddress[]>([]);
  const [reward, setReward] = useState<string>('');
  const [rewardPlural, setRewardPlural] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [restrictionAmount, setRestrictionAmount] = useState<string>('');
  const [restrictionPeriod, setRestrictionPeriod] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [imageUpload, setImageUpload] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [costPrice, setCostPrice] = useState<string>('');
  const [sellPrice, setSellPrice] = useState<string>('');

  // if editing parse all scheme data into the form, if not then all states will be blank
  useEffect(() => {
    if (schemeData !== undefined && !isCreate) {
      setAddStamp(schemeData.addStamp);
      setImages(schemeData.schemeImages);
      setMaxStamps(schemeData.max.toString());
      setImagePreviews([...Array(schemeData.max + 1)]);
      setImageUpload([...Array(schemeData.max + 1)]);
      setReward(schemeData.reward);
      setName(schemeData.name);
      if (schemeData.rewardPlural !== '') setRewardPlural(schemeData.rewardPlural);
      else setRewardPlural('');
      if (schemeData.restrictions?.Amount) setRestrictionAmount(schemeData.restrictions.Amount.toString());
      if (schemeData.restrictions?.Period) setRestrictionPeriod(schemeData.restrictions.Period.toString());
      if (schemeData.CostPrice) setCostPrice(schemeData.CostPrice.toString());
      if (schemeData.SellPrice) setSellPrice(schemeData.SellPrice.toString());
    }

    // parse business location data into correct format
    if (businessData !== undefined) {
      const locationKeys = Object.keys(businessData.Locations);
      let i = 0;
      const locationArray = [];
      while (i < locationKeys.length) {
        let locationEnabled:boolean = false;
        if (schemeData !== undefined && schemeData.locations.length > 0) {
          let j = 0;
          while (j < schemeData.locations.length) {
            if (schemeData.locations[j] === locationKeys[i]) locationEnabled = true;
            j += 1;
          }
        }
        const locationOption: LocationAndAddress = {
          id: locationKeys[i],
          address: businessData.Locations[locationKeys[i]]['Short Address'],
          selected: locationEnabled,
        };
        locationArray.push(locationOption);
        i += 1;
      }
      setLocations(locationArray);
    }
  }, [isCreate, schemeData, open]);

  const handleLocationSelect = (id:string) => {
    let newLocations = locations;
    newLocations = newLocations.map((location) => {
      if (location.id === id) {
        const selected: LocationAndAddress = {
          id: location.id,
          address: location.address,
          selected: !location.selected,
        };
        return selected;
      } return location;
    });
    setLocations(newLocations);
  };

  const handleClose = () => {
    setAddStamp(false);
    setImages([]);
    setName('');
    setMaxStamps('');
    setLocations([]);
    setReward('');
    setRewardPlural('');
    setRestrictionAmount('');
    setRestrictionPeriod('');
    setIsUploading(false);
    setImageUpload([]);
    setImagePreviews([]);
    setErrorText('');
    setOpenErrorDialog(false);
    setOpenConfirm(false);
    setIsSaving(false);
    setOpen(false);
  };

  // on changing max stamp it will reset all preview and upload arrays to the same size as stamps
  const handleStampAmount = (amount: string) => {
    const newAmount = amount.replace(/\D+/g, '');
    setMaxStamps(newAmount);
    if (newAmount !== '') {
      setImagePreviews([...Array(parseInt(newAmount, 10) + 1)]);
      setImageUpload([...Array(parseInt(newAmount, 10) + 1)]);
    }
  };

  const throwError = (error:string) => {
    setOpenConfirm(false);
    setIsSaving(false);
    setOpenErrorDialog(true);
    setErrorText(error);
  };

  // confirming all required inputs are valid before upload
  const checkInputs = ():boolean => {
    let missingImages = false;
    let i = 0;
    while (i < imagePreviews.length) {
      if (imagePreviews[i] === undefined) {
        missingImages = true;
      }
      i += 1;
    }
    if (name === '') {
      throwError('Scheme Name is required');
      return false;
    } if (maxStamps === '') {
      throwError('Max stamp amount is required');
      return false;
    } if (reward === '') {
      throwError('Reward text is required');
      return false;
    }
    if (restrictionAmount !== '' && restrictionPeriod === '') {
      throwError('Restriction period is required');
      return false;
    }
    if (restrictionAmount === '' && restrictionPeriod !== '') {
      throwError('Restriction amount is required');
      return false;
    }
    if (missingImages && isCreate) {
      throwError('An image for each stamp is required');
      return false;
    }
    if (missingImages && !isCreate && parseInt(maxStamps, 10) !== schemeData?.max) {
      throwError('You have changed the max number of stamps. New images are required');
      return false;
    }
    return true;
  };

  // will upload new images and parse new and old into correct array format to be passed to api
  const uploadImage = async () => {
    let urlArray = {};
    // if image preview exists then retrieve correct file and upload. Immediately get upload reference to parse url
    // if old images exist then parse those to array where a new image has not been uploaded
    let newScheme = schemeId;
    if (isCreate) {
      const schemeKeys = Object.keys(businessData.Schemes);
      newScheme = partnerId;
      if (schemeKeys.length === 1) {
        newScheme = `${partnerId}_2`;
      } else if (schemeKeys.length !== 0) {
        const lastId = schemeKeys[schemeKeys.length - 1].split('_')[1];
        const newIdNumber = parseInt(lastId, 10) + 1;
        if (Number.isNaN(newIdNumber)) {
          newScheme = `${partnerId}_2`;
        } else {
          newScheme = `${partnerId}_${newIdNumber}`;
        }
      }
    }

    try {
      const res = await BusinessService.uploadImageBatch(
        partnerId,
        imageUpload,
        'stampcard',
        newScheme,
      );
      urlArray = res;
    } catch (e:any) {
      throwError(`Too many loyalty cards, please only upload ${e.message} images`);
    }

    const lastImage = Object.values(urlArray).pop();
    urlArray = {
      ...urlArray,
      Max: lastImage,
    };
    return urlArray;
  };

  // first check inputs then parse all relevant data into correct format for Scheme upload
  const handleEdit = async () => {
    setOpenConfirm(false);
    const edit = await checkInputs();

    if (edit) {
      setIsSaving(true);
      const finalImages = (imageUpload.some((item) => item === undefined) && schemeData?.schemeImages)
        ? { ...schemeData.schemeImages, Max: schemeData.schemeImages[schemeData.schemeImages.length - 1] }
        : await uploadImage();

      let index = 0;
      let finalLocations = {};
      while (index < locations.length) {
        if (locations[index] && locations[index].selected) {
          finalLocations = {
            ...finalLocations,
            [locations[index].id]: true,
          };
        }
        index += 1;
      }

      let newScheme: V2Scheme = {
        Chain: partnerId,
        'Add Stamp': addStamp,
        Images: finalImages,
        Locations: finalLocations,
        Max: parseInt(maxStamps, 10),
        Name: name,
        Reward: reward,
        partnerId,
      };

      if (rewardPlural !== '') newScheme['Reward Plural'] = rewardPlural;
      if (restrictionAmount !== '' && restrictionPeriod !== '') {
        newScheme.Restrictions = {
          Amount: parseInt(restrictionAmount, 10),
          Period: parseInt(restrictionPeriod, 10),
        };
      }

      if (costPrice !== '' && sellPrice !== '') {
        newScheme = {
          ...newScheme,
          CostPrice: parseFloat(costPrice),
          SellPrice: parseFloat(sellPrice),
        };
      }

      if (isCreate) {
        try {
          await SchemeService.createScheme(partnerId, newScheme);
          loadSchemeDetails(partnerId);
          handleClose();
        } catch (e:any) {
          throwError(e.message);
        }
      } else {
        try {
          await SchemeService.updateScheme(partnerId, schemeId, newScheme);
          loadSchemeDetails(partnerId);
          handleClose();
        } catch (e:any) {
          throwError(e.message);
        }
      }
    }
  };

  // slice new preview into correct position on preview array
  // also add file to upload array
  const previewImage = (files: FileList) => {
    if (files.length !== 0) {
      const requiredAmount = parseInt(maxStamps, 10) + 1;
      setImagePreviews([...Array(requiredAmount)]);
      if (files.length < requiredAmount) {
        throwError(`Missing loyalty cards, please upload ${requiredAmount} images`);
      } else if (files.length > requiredAmount) {
        throwError(`Too many loyalty cards, please only upload ${requiredAmount} images`);
      } else {
        const existingPreviews = imagePreviews.slice();
        const uploadImages = imageUpload;
        Object.values(files).forEach((file) => {
          const fileName = file.name.split('.')[0];
          const index = parseInt(fileName, 10);
          if (Number.isNaN(index)) {
            throwError(`Files are not named correctly. Please ensure they are labeled 0 -> ${requiredAmount}
            for the relevant loyalty card.`);
          } else {
            const image = URL.createObjectURL(file);
            existingPreviews[index] = image;
            uploadImages[index] = file;
          }
        });
        setImagePreviews(existingPreviews);
        setImageUpload(uploadImages);
        setIsUploading(true);
      }
    }
  };

  // on upload image toggle reset [review array]
  const cancelUpload = () => {
    setImagePreviews([...Array(parseInt(maxStamps, 10) + 1)]);
    setImageUpload([...Array(parseInt(maxStamps, 10) + 1)]);
    setIsUploading(false);
  };

  const handleClosed = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleClose();
  };

  return (
    <Dialog
      aria-label="scheme-dialog"
      onClose={(event, reason) => handleClosed(event, reason)}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ zIndex: '3000' }}
    >
      {isCreate ? (
        <StyledTitle variant="h1">Create New Scheme</StyledTitle>
      ) : (
        <StyledTitle variant="h1">{`Edit Scheme Information for ${schemeId}`}</StyledTitle>
      )}

      <DialogContent>
        <Grid container spacing={4}>

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Required Information</Typography></Divider>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="scheme-name-entry"
              label="Scheme Name"
              variant="outlined"
              color="primary"
              value={name}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'off' }}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="max-stamp-entry"
              label="Max number of stamps"
              variant="outlined"
              color="primary"
              value={maxStamps}
              InputLabelProps={{ shrink: true }}
              inputProps={{ pattern: '[1-9]*', autoComplete: 'off' }}
              onChange={(event) => {
                handleStampAmount(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="reward-text-entry"
              label="Reward Text"
              variant="outlined"
              color="primary"
              value={reward}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                autoComplete: 'off',
              }}
              onChange={(event) => {
                setReward(event.target.value.replace(/[.$#\])}[{(]/g, ''));
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body1">Add a stamp after a voucher is redeemed?</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={addStamp} onChange={() => setAddStamp(true)} />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={!addStamp} onChange={() => setAddStamp(false)} />
                }
                label="No"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Required Images</Typography></Divider>
          </Grid>

          {maxStamps !== '' && !isUploading ? (
            <Grid item xs={12}>
              <StyledUploadToggle
                variant="contained"
                component="label"
                fullWidth
                startIcon={<BsCloudUpload />}
              >
                <Typography variant="body2">Upload Scheme Images</Typography>
                <input
                  type="file"
                  hidden
                  multiple
                  accept=".jpg, .jpeg, .png .pdf"
                  onChange={(event) => {
                    if (event.target.files && event.target.files !== null) {
                      previewImage(event.target.files);
                    }
                  }}
                />
              </StyledUploadToggle>
            </Grid>
          ) : null}

          {maxStamps !== '' && isUploading ? (
            <Grid item xs={12}>
              <StyledCancel
                variant="contained"
                fullWidth
                startIcon={<GiCancel />}
                onClick={cancelUpload}
              >
                <Typography variant="body2">Cancel Image Upload</Typography>
              </StyledCancel>
            </Grid>
          ) : null}

          {!isUploading && maxStamps === '' ? (
            <Grid item container xs={12} justifyContent="center">
              <Typography variant="body1">
                Please select a Max number of stamps to begin loyalty card image upload
              </Typography>
            </Grid>
          ) : null}

          {images.length > 0 && !isUploading && parseInt(maxStamps, 10) === schemeData?.max
            ? images.map((schemeImage, index) => (
              <Grid container item xs={12} sm={6} key={index} justifyContent="center">
                <StyledImage src={schemeImage} />
              </Grid>
            )) : null}

          {maxStamps !== '' && parseInt(maxStamps, 10) !== schemeData?.max && !isUploading ? (
            <Grid item container xs={12} justifyContent="center">
              <Typography variant="body1">
                You have changed the number of Max Stamps. Please upload new corresponding images.
              </Typography>
            </Grid>
          ) : null}

          {imagePreviews.length > 0 && isUploading ? imagePreviews.map((schemeImage, index) => (
            <Grid container item xs={12} sm={6} key={index} justifyContent="center">
              <StyledImage src={schemeImage} />
            </Grid>
          )) : null }

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Optional Information</Typography></Divider>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="reward-plural-text-entry"
              label="Reward Plural Text"
              variant="outlined"
              color="primary"
              value={rewardPlural}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setRewardPlural(event.target.value.replace(/[.$#\])}[{(]/g, ''));
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Restriction will stop multiple stamps in quick succession.
              The Amount specifies how many taps before timeout.
              The Period specifies how many seconds until they can tap again.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="reward-plural-text-entry"
              label="Restriction Amount"
              variant="outlined"
              color="primary"
              value={restrictionAmount}
              InputLabelProps={{ shrink: true }}
              inputProps={{ pattern: '[1-9]*', autoComplete: 'off' }}
              onChange={(event) => {
                setRestrictionAmount(event.target.value.replace(/\D+/g, ''));
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="reward-term-text-entry"
              label="Restriction Period"
              variant="outlined"
              color="primary"
              value={restrictionPeriod}
              InputLabelProps={{ shrink: true }}
              inputProps={{ pattern: '[1-9]*', autoComplete: 'off' }}
              onChange={(event) => {
                setRestrictionPeriod(event.target.value.replace(/\D+/g, ''));
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body1">Select Locations you want this scheme assigned to </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup>
              {locations.map((location) => (
                <FormControlLabel
                  key={location.id}
                  control={
                    <Checkbox checked={location.selected} onChange={() => handleLocationSelect(location.id)} />
                  }
                  label={`${location.id} : ${location.address}`}
                />
              ))}
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Optional ROI settings</Typography></Divider>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              ROI is calculated by using the number of stamps and multiple by the Sell Price minus the in
              house Cost Price. ROI = Stamps * Sell Price - Stamps * Cost Price
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Sell Price is the amount the customer pays for the item.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="sell-entry"
              label="Sell Price"
              variant="outlined"
              color="primary"
              value={sellPrice}
              InputLabelProps={{ shrink: true }}
              inputProps={{ pattern: '[1-9]*', autoComplete: 'off' }}
              onChange={(event) => {
                setSellPrice(event.target.value.replace(/\D+/g, ''));
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Cost Price is the in house cost of the item.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="sell-entry"
              label="Cost Price"
              variant="outlined"
              color="primary"
              value={costPrice}
              InputLabelProps={{ shrink: true }}
              inputProps={{ pattern: '[1-9]*', autoComplete: 'off' }}
              onChange={(event) => {
                setCostPrice(event.target.value.replace(/\D+/g, ''));
              }}
            />
          </Grid>

        </Grid>
      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={() => handleClose()}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>
        <StyledConfirm
          variant="contained"
          fullWidth
          startIcon={<GiConfirmed />}
          onClick={() => {
            const passed = checkInputs();
            if (passed) setOpenConfirm(true);
          }}
        >
          <Typography variant="body2">CONFIRM</Typography>
        </StyledConfirm>
      </DialogActions>

      <ConfirmDialog
        open={openConfirm}
        handleCancel={() => setOpenConfirm(false)}
        handleConfirm={handleEdit}
        dialogTitle={isCreate ? 'Save new Scheme' : 'Save Changes'}
        dialogText={isCreate
          ? 'Are you sure you wish to save this new scheme ?'
          : 'Are you sure you wish to save these changes to this existing scheme ?'}
      />

      <SavingChangesDialog
        open={isSaving}
        dialogTitle={isCreate ? `Creating new Scheme ${schemeId}` : `Updating Scheme ${schemeId}`}
      />

      <ErrorDialog
        open={openErrorDialog}
        dialogTitle=""
        dialogText={errorText}
        handleClose={() => {
          setOpenErrorDialog(false);
          setErrorText('');
        }}
      />

    </Dialog>
  );
}

export default SchemeEdit;
