import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  IconButton,
  Grid,
  TextField,
  Typography,
  Button,
  InputAdornment,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { BiLogInCircle } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import EntryDialog from '../../../Components/BasicComponents/EntryDialog';

import AuthService from '../../../Services/AuthService';
import NotificationService from '../../../Services/NotificationService';

const StyleContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingRight: '50px',
  paddingLeft: '50px',
  paddingTop: '50px',
  paddingBottom: '20px',
  marginLeft: '90px',
  marginRight: '90px',
  borderRadius: '10px',
  borderColor: theme.palette.primary.main,
  boxShadow: '0px 0px 30px rgba(0,0,0,0.45)',
}));

const StyledLogin = styled(LoadingButton)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  height: '55px',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const ForgotPasswordButton = styled(Button, {})({
  height: '35px',
  textTransform: 'none',
});

const ForgotPasswordText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  width: '100%',
  textAlign: 'center',
}));

function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [forgotEmail, setForgotEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openForgot, setOpenForgot] = useState<boolean>(false);
  const [forgotPassError, setForgotPassError] = useState<boolean>(false);
  const [forgotPassErrorMessage, setForgotPassErrorMessage] = useState<string>('');

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await AuthService.signIn(email, password);
      try {
        await NotificationService.getPendingNotificationAmount();
      } catch (error) {
        console.log(error);
      }
      setPassword('');
      setEmail('');
      setForgotEmail('');
      navigate('/users');
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMessage(error);
    }
  };

  const handleForgot = async () => {
    try {
      await AuthService.resetPassword(forgotEmail);
      setForgotEmail('');
      setOpenForgot(false);
    } catch (error: any) {
      setForgotPassError(true);
      setForgotPassErrorMessage('That Email does not exits in our system');
    }
  };

  return (
    <StyleContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="email-entry"
            label="Email Address"
            variant="outlined"
            color="primary"
            value={email}
            InputLabelProps={{ shrink: true }}
            inputProps={{ autoComplete: 'off' }}
            onChange={(event) => {
              if (errorMessage !== '') setErrorMessage('');
              setEmail(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="password-entry"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            color="primary"
            value={password}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              autoComplete: 'off',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              if (errorMessage !== '') setErrorMessage('');
              setPassword(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <StyledLogin
            variant="contained"
            fullWidth
            startIcon={<BiLogInCircle />}
            onClick={handleSubmit}
            loading={loading}
            disabled={errorMessage !== ''}
          >
            <Typography variant="body2">Sign In</Typography>
          </StyledLogin>
        </Grid>

        {errorMessage !== '' ? (
          <Grid item container xs={12} justifyContent="center">
            <ErrorText variant="body1">{errorMessage}</ErrorText>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <ForgotPasswordButton
            variant="text"
            fullWidth
            onClick={() => {
              setForgotEmail(email);
              setOpenForgot(true);
            }}
          >
            <ForgotPasswordText variant="body1">Forgot Password ?</ForgotPasswordText>
          </ForgotPasswordButton>
        </Grid>
      </Grid>

      {openForgot ? (
        <EntryDialog
          open={openForgot}
          dialogTitle="Please enter in your email"
          dialogText="To note you will only be in our system if you have previously created a squid account through
          the use of that email on the SQUID App."
          handleCancel={() => {
            setForgotPassError(false);
            setForgotPassErrorMessage('');
            setOpenForgot(false);
          }}
          handleConfirm={handleForgot}
          value={forgotEmail}
          setValue={setForgotEmail}
          disableEntryFormat
          error={forgotPassError}
          errorMessage={forgotPassErrorMessage}
        />
      ) : null}
    </StyleContainer>
  );
}

export default Login;
