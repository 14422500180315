const API_URL = process.env.REACT_APP_CAMPAIGN_API_URL;

export type CampaignEditBody = {
  description: string,
  startEpoch: number,
  campaignType: string,
  messageHeader?: string,
  messageBody?: string,
  rewardExpiryDays?: number,
  endEpoch?: number,
};

export type CodeAnalytics = {
  codeId: string,
  label: string,
  promotionClaimsCount: number,
  promotionRedemptionsCount: number,
  promotionRewardsDelivered: number,
  returnOnInvestment: string,
  subsequentPurchase: number,
  twelveMonthROI: string,
};

export type CampaignAnalytics = {
  messageId: string,
  description: string,
  campaignId: string,
  readCount: number,
  seeMoreCount: number,
  linkeClickedCount: number,
  openedNotifications: number,
  startEpoch: number,
  endEpoch: number,
  campaignType: string,
  rewardType: string,
  promoCodesArray: CodeAnalytics[],
  status: string,
  rewardQuantity: number,
  promotionClaimsCount: number
  promotionRedemptionsCount: number,
  promotionRewardsDelivered: number,
  subsequentPurchase: number,
  rewardCost: number,
  rewardPrice: number,
  returnOnInvestment: number,
  twelveMonthROI: number,
  businessId: string,
  deliveredVsRead: number,
  readVsClaimed: number,
  claimedVsRedeemed: number,
};

export type MessageChannelCreate = {
  campaignId: string,
  ctaType: string,
  message: string,
  messageTitle: string,
  promotionTitle: string,
  rewardTitle: string,
  link?: string,
  linkTitle?: string,
};

export type MessageDataBody = {
  businessName: string,
  ctaType?: string,
  image?: string
  link?: string,
  linkTitle?: string,
  messageBody: string,
  messageTitle: string,
  promotionId: string,
  promotionTitle: string,
  rewardTitle?: string,
};

export type CampaignBody = {
  campaignType: string,
  businessId: string,
  schemeId: string,
  description?: string,
  rewardType: string,
  rewardQuantity: number,
  startEpoch?: number,
  endEpoch?: number,
  reward: string,
  rewardExpiryDays?: number,
  locationIds?: string,
  messageData?: {
    [key: string]: MessageDataBody
  },
  status?: string,
};

export type MessageChannel = { [key: string]: MessageDataBody };
export type Campaign = CampaignBody & { campaignId: string };

export type PromoCodes = { label: string, value: string };

export type CreateCampaign = {
  businessId: string,
  schemeId: string,
  totalAmount: number,
  amountReceived: number,
  locationIds: string[],
  description: string,
  reward: string,
  rewardType: string,
  startEpoch: number,
  endEpoch: number,
  campaignType: string,
  messageHeader?: string,
  messageBody?: string,
  rewardExpiryDays?: number | null,
  emailAddress?: string,
  voucherText?: string,
  promoCodes?: PromoCodes[],
};

export type CreateCustomCampaign = {
  name: string,
  businessId: string,
  schemeId: string,
  sendTime: number,
  userEmail: string,
  criteria: {},
  notification: {
    body: string,
    header: string,
    rewardExpiryDays: number,
    voucherText: string
    voucher: boolean,
  },
};

const getAllCampaigns = (): Promise<Campaign[]> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/campaigns`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      if (json.length === 0) reject(json);
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getCampaignAnalytics = (campaignId: string): Promise<CampaignAnalytics> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/campaigns/analytics/${campaignId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const deleteCampaign = (campaignId: string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/campaigns/${campaignId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const updateCampaign = (campaignId: string, campaign: CampaignEditBody) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/campaigns/${campaignId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(campaign),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const createCampaign = (campaign: CreateCampaign) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/campaigns`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(campaign),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const createCustomCampaign = (customCampaign: CreateCustomCampaign) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/notifications`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(customCampaign),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const createMessageChannel = (messageChannel: MessageChannelCreate) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/messages`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...messageChannel }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const pauseCampaign = (campaignId: string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/campaigns/${campaignId}/pause`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const resumeCampaign = (campaignId: string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/campaigns/${campaignId}/resume`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const CampaignsService = {
  getCampaignAnalytics,
  createMessageChannel,
  deleteCampaign,
  getAllCampaigns,
  updateCampaign,
  createCampaign,
  pauseCampaign,
  resumeCampaign,
  createCustomCampaign,
};

export default CampaignsService;
