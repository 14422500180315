import React, { Fragment, useState } from 'react';

import { styled } from '@mui/system';
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  IconButtonProps,
  Grid,
  Typography,
  Tooltip,
} from '@mui/material';
import { MdExpandMore, MdOutlinePlayCircle } from 'react-icons/md';
import { FaEdit, FaChartBar, FaRegPauseCircle } from 'react-icons/fa';
import { AiFillDelete, AiFillPlusCircle } from 'react-icons/ai';

import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import ValidateEpoch from '../../../util/validateEpoch';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import MessageDialog from './MessageDialog';
import CampaignEdit from './CampaignEdit';
import CampaignsService, { Campaign, CampaignAnalytics } from '../../../Services/CampaignsService';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  marginRight: '20px',
  '&:hover': {
    color: theme.palette.warning.main,
  },
}));

const StyledCreateButton = styled(Button)(({ theme }) => ({
  height: '55px',
  fontSize: '18px',
  fontWeight: 'bold',
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  [theme.breakpoints.down('sm')]: {
    height: '35px',
  },
}));

type Props = {
  campaignData: Campaign,
  loadCampaigns: (param?: string) => void,
};

function CampaignCard(props: Props) {
  const {
    campaignData,
    loadCampaigns,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [openPauseResume, setOpenPauseResume] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [openMessageCreate, setOpenMessageCreate] = useState<boolean>(false);
  const [campaignAnalytics, setCampaignAnalytics] = useState<CampaignAnalytics>();
  const [isActive, setIsActive] = useState<boolean>(campaignData.status === 'active');

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const deleteCampaign = async () => {
    try {
      await CampaignsService.deleteCampaign(campaignData.campaignId);
      loadCampaigns();
    } catch (e:any) {
      setErrorText(e.message);
      setOpenError(true);
    }
    setOpenDelete(false);
  };

  const fetchAnalytics = async () => {
    try {
      const analytics = await CampaignsService.getCampaignAnalytics(campaignData.campaignId);
      setCampaignAnalytics(analytics);
    } catch (e:any) {
      setErrorText(e.message);
      setOpenError(true);
    }
    setOpenDelete(false);
  };

  const handlePauseResume = async () => {
    setOpenPauseResume(false);
    try {
      if (isActive) {
        await CampaignsService.pauseCampaign(campaignData.campaignId);
      } else {
        await CampaignsService.resumeCampaign(campaignData.campaignId);
      }

      setIsActive(!isActive);
    } catch (e: any) {
      setErrorText(e.message);
      setOpenError(true);
    }
  };

  const permissions = ['loyalty:promo:update', 'loyalty:promo:all'];
  const canEdit = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  return (
    <Card key={1}>
      <CardTitle>
        <CardTitleText variant="h4">
          {`${campaignData.businessId} - ${campaignData.campaignType} - ${campaignData.status}`}
        </CardTitleText>

        <div>
          <Tooltip
            title={isActive ? 'pause this Campaign' : 'resume this Campaign'}
            placement="bottom"
          >
            <StyledIconButton
              disabled={!canEdit || campaignData.status === null || campaignData.status === 'pending'}
              size="small"
              onClick={() => setOpenPauseResume(true)}
            >
              {isActive ? <FaRegPauseCircle /> : <MdOutlinePlayCircle /> }
            </StyledIconButton>
          </Tooltip>

          <Tooltip title="Edit this Campaign" placement="bottom">
            <StyledIconButton disabled={!canEdit} size="small" onClick={() => setOpenEdit(true)}>
              <FaEdit />
            </StyledIconButton>
          </Tooltip>

          <Tooltip title="Delete this Campaign" placement="bottom">
            <StyledIconButton disabled={!canEdit} size="small" onClick={() => setOpenDelete(true)}>
              <AiFillDelete />
            </StyledIconButton>
          </Tooltip>

          <ExpandMore
            expand={isExpanded}
            onClick={HandleExpandClick}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>

      </CardTitle>

      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Divider><Typography variant="body2">Campaign Details</Typography></Divider>
            </Grid>

            {campaignData.campaignId ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Campaign ID :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${campaignData.campaignId}`}</Typography>
                </Grid>
              </>
            ) : null}

            {campaignData.campaignType ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Campaign Type :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${campaignData.campaignType}`}</Typography>
                </Grid>
              </>
            ) : null}

            <Grid item xs={12}>
              <Divider><Typography variant="body2">Reward Details</Typography></Divider>
            </Grid>

            {campaignData.reward ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Reward Text :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${campaignData.reward}`}</Typography>
                </Grid>
              </>
            ) : null}

            {campaignData.rewardType ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Reward Type :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${campaignData.rewardType}`}</Typography>
                </Grid>
              </>
            ) : null}

            {campaignData.rewardQuantity ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Reward Quantity :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${campaignData.rewardQuantity}`}</Typography>
                </Grid>
              </>
            ) : null}

            {campaignData.rewardType === 'voucher' || campaignData.rewardType === 'accelerator' ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    {campaignData.rewardType === 'accelerator'
                      ? 'Accelerator ends after : '
                      : 'Voucher Expires after : '}
                  </Typography>
                </Grid>

                {campaignData.rewardExpiryDays ? (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{`${campaignData.rewardExpiryDays} Days`}</Typography>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      Never
                    </Typography>
                  </Grid>
                )}
              </>
            ) : null}

            {campaignData.startEpoch ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Campaign Start :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    {`${new Date(ValidateEpoch(campaignData.startEpoch)).toLocaleString()}`}
                  </Typography>
                </Grid>
              </>
            ) : null}

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Campaign End :</Typography>
            </Grid>

            {campaignData.endEpoch ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  {`${new Date(ValidateEpoch(campaignData.endEpoch)).toLocaleString()}`}
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  Never
                </Typography>
              </Grid>
            )}

            {campaignData.description ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Internal Description :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${campaignData.description}`}</Typography>
                </Grid>
              </>
            ) : null}

            {campaignData.campaignType !== 'acquisition' ? (
              <>
                <Grid item xs={12}>
                  <Divider><Typography variant="body2">Message Inbox</Typography></Divider>
                </Grid>

                {campaignData.messageData && Object.keys(campaignData.messageData).length > 0 ? (
                  <>
                    {Object.keys(campaignData.messageData).map((messageId, index) => (
                      <Fragment key={index}>

                        <Grid item xs={12} sm={6}>
                          <Typography variant="body2">Message Title :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body1">
                            {campaignData.messageData && campaignData.messageData[messageId].messageTitle}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant="body2">Message Body :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body1">
                            {campaignData.messageData && campaignData.messageData[messageId].messageBody}
                          </Typography>
                        </Grid>

                        {campaignData.messageData && campaignData.messageData[messageId].rewardTitle ? (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body2">Reward Banner Text :</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body1">
                                {campaignData.messageData[messageId].rewardTitle}
                              </Typography>
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xs={12} sm={6}>
                          <Typography variant="body2">Reward Type :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body1">
                            {campaignData.messageData && campaignData.messageData[messageId].ctaType}
                          </Typography>
                        </Grid>

                        {campaignData.messageData && campaignData.messageData[messageId].link ? (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body2">Link :</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body1">
                                {campaignData.messageData && campaignData.messageData[messageId].link}
                              </Typography>
                            </Grid>
                          </>
                        ) : null}

                        {campaignData.messageData && campaignData.messageData[messageId].linkTitle ? (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body2">Link Title :</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body1">
                                {campaignData.messageData && campaignData.messageData[messageId].linkTitle}
                              </Typography>
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xs={12} sm={6}>
                          <Typography variant="body2">Message Id :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body1">
                            {messageId}
                          </Typography>
                        </Grid>

                      </Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={12} container justifyContent="center">
                      <Typography variant="body2">
                        No Messages tied to this campaign
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} container justifyContent="center">
                      <StyledCreateButton
                        aria-label="message-create"
                        variant="contained"
                        fullWidth
                        startIcon={<AiFillPlusCircle />}
                        onClick={() => {
                          setOpenMessageCreate(true);
                        }}
                      >
                        <Typography variant="body2">Create New Message</Typography>
                      </StyledCreateButton>
                    </Grid>
                  </>
                )}

              </>
            ) : null }

            <Grid item xs={12}>
              <Divider><Typography variant="body2">Store Details</Typography></Divider>
            </Grid>

            {campaignData.businessId ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Business ID :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${campaignData.businessId}`}</Typography>
                </Grid>
              </>
            ) : null}

            {campaignData.schemeId ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Scheme ID :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${campaignData.schemeId}`}</Typography>
                </Grid>
              </>
            ) : null}

            {campaignData.locationIds ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">location IDs :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {campaignData?.locationIds?.slice(1, -1).replace(/'/g, '')
                    .split(', ').map((location) => (
                      <Typography variant="body1" key={location}>
                        {`${location}`}
                      </Typography>
                    ))}
                </Grid>
              </>
            ) : null}

            <Grid item xs={12}>
              <Divider><Typography variant="body2">Campaign Analytics</Typography></Divider>
            </Grid>

            {campaignAnalytics ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Messages Delivered :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    {`${campaignAnalytics.promotionRewardsDelivered}`}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Messages Read :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    {`${campaignAnalytics.readCount}`}
                  </Typography>
                </Grid>

                {campaignAnalytics.readCount !== 0 && campaignAnalytics.promotionRewardsDelivered !== 0 ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Delivered Vs. Read Percentage :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {`${((campaignAnalytics.readCount
                          / campaignAnalytics.promotionRewardsDelivered) * 100).toFixed(3)}%`}
                      </Typography>
                    </Grid>

                  </>
                ) : null}

                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Promotions Claimed :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    {`${campaignAnalytics.promotionClaimsCount}`}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Promotions Redeemed :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    {`${campaignAnalytics.promotionRedemptionsCount}`}
                  </Typography>
                </Grid>

                {campaignAnalytics.promotionRedemptionsCount !== 0 && campaignAnalytics.promotionClaimsCount !== 0 ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Claimed Vs. Redeemed Percentage :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {`${((campaignAnalytics.promotionRedemptionsCount
                          / campaignAnalytics.promotionClaimsCount) * 100).toFixed(3)}%`}
                      </Typography>
                    </Grid>
                  </>
                ) : null}

                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Opened via Push Notification :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    {`${campaignAnalytics.openedNotifications || 0}`}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Amount clicked `See More` :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    {`${campaignAnalytics.seeMoreCount}`}
                  </Typography>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={12} container justifyContent="center">
                <StyledCreateButton
                  aria-label="fetch-analytics"
                  variant="contained"
                  fullWidth
                  startIcon={<FaChartBar />}
                  onClick={() => {
                    fetchAnalytics();
                  }}
                >
                  <Typography variant="body2">Get Campaign Analytics</Typography>
                </StyledCreateButton>
              </Grid>
            )}

          </Grid>
        </ExpandedContent>
      </Collapse>

      {openDelete ? (
        <ConfirmDialog
          open={openDelete}
          dialogTitle={`Delete Campaign for ${campaignData.businessId}`}
          dialogText="Are you sure you want to delete this campaign? this cannot be reversed"
          handleCancel={() => setOpenDelete(false)}
          handleConfirm={() => deleteCampaign()}
        />
      ) : null}

      {openError ? (
        <ErrorDialog
          dialogTitle="Error"
          dialogText={errorText}
          open={openError}
          handleClose={() => {
            setOpenError(false);
            setErrorText('');
          }}
        />
      ) : null}

      {openEdit ? (
        <CampaignEdit
          key={new Date().getTime()}
          open={openEdit}
          setOpen={setOpenEdit}
          campaignData={campaignData}
          businessId={campaignData.businessId}
          loadCampaigns={loadCampaigns}
        />
      ) : null}

      {openPauseResume ? (
        <ConfirmDialog
          open={openPauseResume}
          dialogTitle={`${isActive ? 'Pause' : 'Resume'} Campaign for ${campaignData.businessId}`}
          dialogText={isActive
            ? `Pausing a campaign will cease all functionality until it is resumed.
            Are you sure you want to do this?`
            : `Resuming a campaign will make all its rewards available to be claimed. 
            Are you sure you want to do this?`}
          handleCancel={() => setOpenPauseResume(false)}
          handleConfirm={handlePauseResume}
        />
      ) : null}

      {openMessageCreate ? (
        <MessageDialog
          getCampaigns={loadCampaigns}
          campaignId={campaignData.campaignId}
          open={openMessageCreate}
          setOpen={setOpenMessageCreate}
        />
      ) : null}
    </Card>
  );
}

export default CampaignCard;
