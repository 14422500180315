/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import {
  DialogActions,
  DialogContent,
  Button,
  Dialog,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Divider,
} from '@mui/material';
import { styled } from '@mui/system';
import { GiCancel, GiConfirmed } from 'react-icons/gi';

import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import SavingChangesDialog from '../../../Components/BasicComponents/SavingChangesDialog';
import CampaignsService, { MessageChannelCreate } from '../../../Services/CampaignsService';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '25px',
  paddingBottom: '10px',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

type Props = {
  open: boolean,
  setOpen: (toggle: boolean) => void,
  campaignId: string,
  getCampaigns: () => void,
};

function MessageDialog(props: Props) {
  const {
    open,
    setOpen,
    campaignId,
    getCampaigns,
  } = props;

  const [ctaType, setCtaType] = useState<string>('none');
  const [webLink, setWebLink] = useState<string>('');
  const [linkText, setLinkText] = useState<string>('');
  const [promotionTitle, setPromotionTitle] = useState<string>('');
  const [messageTitle, setMessageTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [rewardTitle, setRewardTitle] = useState<string>('');
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openSaving, setOpenSaving] = useState<boolean>(false);

  const handleClose = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    setCtaType('none');
    setWebLink('');
    setLinkText('');
    setPromotionTitle('');
    setMessageTitle('');
    setMessage('');
    setRewardTitle('');
    setOpen(false);
  };

  const throwError = (error:string) => {
    setOpenConfirm(false);
    setOpenErrorDialog(true);
    setErrorText(error);
  };

  const handleCreate = async () => {
    setOpenConfirm(false);
    setOpenSaving(true);

    try {
      let messageChannel: MessageChannelCreate = {
        campaignId,
        promotionTitle,
        messageTitle,
        message,
        ctaType,
        rewardTitle,
      };
      if (ctaType === 'webLink') {
        messageChannel = {
          ...messageChannel,
          link: webLink,
          linkTitle: linkText,
        };
      }

      await CampaignsService.createMessageChannel(messageChannel);
      getCampaigns();
    } catch (e: any) {
      throwError(e.message);
    }
    setOpenSaving(false);
    setOpen(false);
  };

  // campaignId,
  // messageTitle,
  // promotionTitle,
  // message,
  // rewardTitle,
  // ctaType

  return (
    <Dialog
      aria-label="team-edit"
      onClose={(event, reason) => handleClose(event, reason)}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ zIndex: '3000' }}
    >

      <StyledTitle variant="h1">Create Campaign Message</StyledTitle>

      <DialogContent>
        <Grid container direction="column" spacing={3}>

          <Grid item xs={12}>
            <Typography variant="body2">
              Message Description
            </Typography>
            <Typography variant="body1">
              This is a description for internal use that is used to add context to the message promotion
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="promo-title"
              label="Description"
              variant="outlined"
              color="primary"
              value={promotionTitle}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setPromotionTitle(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              Button Type
            </Typography>
            <Typography variant="body1">
              This is a button displayed under the message within the app message inbox.
              It can be used to add links or directions to a message
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              id="cta-type"
              label="Button Type"
              variant="outlined"
              color="primary"
              value={ctaType}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setCtaType(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            >
              <MenuItem key={0} value="" disabled>Select one of the below</MenuItem>
              <MenuItem key={1} value="none">None</MenuItem>
              <MenuItem key={2} value="webLink">Web Link</MenuItem>
            </TextField>
          </Grid>

          {ctaType === 'webLink' ? (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Button Text
                </Typography>
                <Typography variant="body1">
                  This is the text that will be shown under the message within the app message inbox
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="button-text"
                  label="Link Button Text"
                  variant="outlined"
                  color="primary"
                  value={linkText}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => {
                    setLinkText(event.target.value);
                  }}
                  InputProps={{
                    autoComplete: 'off',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Link
                </Typography>
                <Typography variant="body1">
                  This is the actual link you wish to direct users too once they click the button
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="link"
                  label="Link"
                  variant="outlined"
                  color="primary"
                  value={webLink}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => {
                    setWebLink(event.target.value);
                  }}
                  InputProps={{
                    autoComplete: 'off',
                  }}
                />
              </Grid>

            </>
          ) : null}

          <Grid item xs={12}>
            <Typography variant="body2">
              Message Title
            </Typography>
            <Typography variant="body1">
              This is the title of the message , will be shown as a push notification and in the message inbox screen
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="message-title"
              label="Message Title"
              variant="outlined"
              color="primary"
              value={messageTitle}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setMessageTitle(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              Message Body
            </Typography>
            <Typography variant="body1">
              This is the body of the message , will be shown as a push notification and in the message inbox screen
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="message-body"
              label="Message Body"
              variant="outlined"
              color="primary"
              value={message}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setMessage(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              Reward Title
            </Typography>
            <Typography variant="body1">
              This is shown as a yellow promotional banner under the business name in the message inbox
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="reward-title"
              label="Reward Title"
              variant="outlined"
              color="primary"
              value={rewardTitle}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setRewardTitle(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

        </Grid>
      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={handleClose}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>
        <StyledConfirm
          variant="contained"
          fullWidth
          startIcon={<GiConfirmed />}
          onClick={() => {
            setOpenConfirm(true);
          }}
          disabled={
            promotionTitle === ''
            || messageTitle === ''
            || message === ''
            || rewardTitle === ''
            || (ctaType === 'webLink' && (linkText === '' || webLink === ''))
          }
        >
          <Typography variant="body2">CONFIRM</Typography>
        </StyledConfirm>
      </DialogActions>

      <ConfirmDialog
        open={openConfirm}
        handleCancel={() => {
          setOpenConfirm(false);
        }}
        handleConfirm={() => {
          handleCreate();
        }}
        dialogTitle="Save Changes"
        dialogText="Are you sure you want to create this message ?"
      />

      <ErrorDialog
        open={openErrorDialog}
        dialogTitle="Error Creating Message Changes"
        dialogText={errorText}
        handleClose={() => {
          setOpenErrorDialog(false);
          setErrorText('');
        }}
      />

      <SavingChangesDialog
        open={openSaving}
        dialogTitle="Creating Message Channel"
      />

    </Dialog>
  );
}

export default MessageDialog;
