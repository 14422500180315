/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

import {
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Dialog,
  Grid,
  Typography,
  MenuItem,
  FormGroup,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { styled } from '@mui/system';
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import dayjs, { Dayjs } from 'dayjs';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import AlertDialog from '../../../Components/BasicComponents/AlertDialog';
import CampaignsService, { Campaign, CreateCampaign, CreateCustomCampaign } from '../../../Services/CampaignsService';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '25px',
  paddingBottom: '10px',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

const StyledGenerate = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  height: '54px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

type Option = {
  value: string,
  display: string,
  reward: string,
};

type CampaignCreateProps = {
  open: boolean,
  setOpen: (toggle: boolean) => void,
  campaignData?: Campaign
  businessId: string,
  isCreate?: boolean,
  businessData: any,
};

function CampaignCreate(props: CampaignCreateProps) {
  const {
    open,
    setOpen,
    campaignData,
    businessId,
    isCreate = false,
    businessData,
  } = props;

  const now = dayjs(Date.now());

  const [type, setType] = useState<string>('');
  const [schemeOptions, setSchemeOptions] = useState<Option[]>([]);
  const [scheme, setScheme] = useState<string>('');
  const [rewardType, setRewardType] = useState<string>('');
  const [totalAmount, setTotalAmount] = useState<string>('');
  const [amountCustomer, setAmountCustomer] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [locationOptions, setLocationOptions] = useState<Option[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [rewardExpiry, setRewardExpiry] = useState<string>('');
  const [startTime, setStartTime] = useState<Dayjs>(now);
  const [endTime, setEndTime] = useState<Dayjs>(now);
  const [unlimitedRewards, setUnlimitedRewards] = useState<boolean>(false);
  const [startNow, setStartNow] = useState<boolean>(false);
  const [neverEnd, setNeverEnd] = useState<boolean>(false);
  const [voucherText, setVoucherText] = useState<string>('');
  const [messageHeader, setMessageHeader] = useState<string>('');
  const [messageBody, setMessageBody] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [codeAmount, setCodeAmount] = useState<string>('');
  const [promoCodes, setPromoCodes] = useState<{ label: string, value: string }[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const checkInputs = ():boolean => {
    if (
      type === ''
      || rewardType === ''
      || (totalAmount === '' && !unlimitedRewards)
      || selectedLocations.length === 0
      || description === ''
      || (type === 'acquisition' && codeAmount === '')
      || (type === 'acquisition' && promoCodes.length === 0)
      || (type !== 'custom' && scheme === '')
      || (type !== 'custom' && (endTime <= now && !neverEnd))
      || (type !== 'custom' && endTime <= startTime && !startNow && !neverEnd)
      || (type !== 'custom' && amountCustomer === '')
      || (type !== 'acquisition' && messageBody === '')
      || (type !== 'acquisition' && messageHeader === '')
    ) {
      return false;
    }

    setError(false);
    return true;
  };

  const createCampaign = async () => {
    let campaign: CreateCampaign = {
      businessId,
      schemeId: scheme,
      totalAmount: unlimitedRewards ? -1 : parseInt(totalAmount, 10),
      amountReceived: parseInt(amountCustomer, 10),
      locationIds: selectedLocations,
      description,
      rewardType,
      campaignType: type,
      rewardExpiryDays: type === 'acquisition'
        ? null
        : rewardExpiry ? parseInt(rewardExpiry, 10) : 30,
      startEpoch: startNow ? 0 : dayjs(startTime).valueOf(),
      endEpoch: neverEnd ? 0 : dayjs(endTime).valueOf(),
      emailAddress: emailAddress ?? '',
      voucherText,
      reward: scheme,
    };

    if (type === 'acquisition') {
      campaign = {
        ...campaign,
        promoCodes,
      };
    } else {
      campaign = {
        ...campaign,
        messageHeader,
        messageBody,
      };
    }

    try {
      await CampaignsService.createCampaign(campaign);
      setOpenConfirm(false);
      setOpenAlert(true);
    } catch (e:any) {
      setOpenConfirm(false);
      setErrorMessage(e.message);
      setOpenError(true);
    }
  };

  // custom 'campaigns' are still made through the notification feature and have a different structure
  const createCustomNotification = async () => {
    const customCampaign: CreateCustomCampaign = {
      name: description,
      businessId,
      schemeId: 'custom',
      sendTime: startNow ? now.valueOf() : dayjs(startTime).valueOf(),
      userEmail: emailAddress ?? '',
      criteria: {},
      notification: {
        body: messageBody,
        header: messageHeader,
        rewardExpiryDays: rewardExpiry ? parseInt(rewardExpiry, 10) : 30,
        voucherText,
        voucher: true,
      },
    };

    try {
      await CampaignsService.createCustomCampaign(customCampaign);
      setOpenConfirm(false);
      setOpenAlert(true);
    } catch (e:any) {
      setOpenConfirm(false);
      setErrorMessage(e.message);
      setOpenError(true);
    }
  };

  const generateCodes = () => {
    if (codeAmount !== '') {
      const codes: { label: string, value: string }[] = Array.from({ length: parseInt(codeAmount, 10) });
      const prefix = businessId?.slice(0, 3);

      codes.forEach((promoCode, index) => {
        let code = '';
        if (prefix) {
          const suffix = Math.random()
            .toString(36)
            .substring(2, 6)
            .toUpperCase();
          code = `${prefix}${suffix}`;
        } else {
          code = Math.random()
            .toString(36)
            .substring(2, 9)
            .toUpperCase();
        }
        codes[index] = { label: '', value: code };
      });
      setPromoCodes(codes);
    }
  };

  useEffect(() => {
    if (businessData.Schemes) {
      const schemeIds = Object.keys(businessData.Schemes);
      const schemeOpts:Option[] = schemeIds.map((id) => (
        { value: id, display: `${id} - ${businessData.Schemes[id].Reward}`, reward: businessData.Schemes[id].Reward }
      ));
      setSchemeOptions(schemeOpts);
    }
    if (businessData.Locations) {
      const locations = Object.keys(businessData.Locations);
      const locationOpts:Option[] = locations.map((id) => (
        { value: id, display: `${id} - ${businessData.Locations[id].Address}`, reward: id }
      ));
      setLocationOptions(locationOpts);
      setSelectedLocations(locations);
    }
  }, [businessId, campaignData]);

  const handleClosed = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleClose();
  };

  return (
    <Dialog
      aria-label="campaign-dialog"
      onClose={(event, reason) => handleClosed(event, reason)}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ zIndex: '1100' }}
    >

      <StyledTitle variant="h1">{`Create Campaign For ${businessData['Basic Fields']['Chain Name']}`}</StyledTitle>

      <DialogContent>
        <Grid container spacing={4}>

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Campaign Type</Typography></Divider>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Acquisition Campaign</Typography>
            <Typography variant="body1">
              This campaign is used to generate promotion codes that the target business can use in other promotional material. These
              codes are then tracked and used to view statistics on customer acquisition and revenue generated.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Retention Campaign</Typography>
            <Typography variant="body1">
              This campaign is designed to retain SQUID customers for a longer period of time after their first stamp. The customer will receive
              an inbox message thanking them for their first visit along with a claimable first stamp reward. This reward can be stamps , vouchers or an &apos;accelerator&apos;
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Birthday Campaign</Typography>
            <Typography variant="body1">
              This campaign is designed to send out an in-app inbox message with a claimable reward on the date of a customers
              birthday who have previously stamped at this store.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Custom Campaign</Typography>
            <Typography variant="body1">
              This campaign is designed to be used when a business wants to offer a reward that is not currently on one of their schemes. The
              reward is sent out via the inbox along with a customisable message.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              id="type-select"
              label="Campaign Type"
              variant="outlined"
              color="primary"
              value={type}
              error={error && type === ''}
              onChange={(event) => {
                setType(event.target.value);
                if (event.target.value === 'custom') setRewardType('voucher');
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            >
              <MenuItem key={0} value="" disabled>Select one of the below</MenuItem>
              <MenuItem key={1} value="acquisition">Acquisition</MenuItem>
              <MenuItem key={2} value="retention">Retention</MenuItem>
              <MenuItem key={3} value="birthday">Birthday</MenuItem>
              <MenuItem key={4} value="custom">Custom</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Reward Settings</Typography></Divider>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Reward Type</Typography>
            <Typography variant="body1">Select which type of reward this campaign is giving out. Custom Campaigns can only be of type voucher.</Typography>
          </Grid>

          {type === 'retention' ? (
            <Grid item xs={12}>
              <Typography variant="body2">Accelerator Explainer</Typography>
              <Typography variant="body1">
                Retention campaigns have the option of selecting an &apos;accelerator&apos; here as a reward. This is an option to offer a first customer a stamp
                multiplier for a specific duration. E.g after a new customers first stamp they will receive an inbox message indicating they are
                now on an &apos;accelerator&apos; and will receive multiple stamps on each purchase for a set duration. This is used to incentivize new customers
                to return to a business
              </Typography>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              id="reward-select"
              disabled={type === 'custom'}
              label="Reward Type"
              variant="outlined"
              color="primary"
              value={rewardType}
              error={error && rewardType === ''}
              onChange={(event) => {
                setRewardType(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            >
              <MenuItem key={0} value="" disabled>Select one of the below</MenuItem>
              <MenuItem key={1} value="stamp">Stamp</MenuItem>
              <MenuItem key={2} value="voucher">Voucher</MenuItem>
              {type === 'retention' ? <MenuItem key={3} value="accelerator">Accelerator</MenuItem> : null}
            </TextField>
          </Grid>

          {rewardType === 'accelerator' ? (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">Accelerator Run Period</Typography>
                <Typography variant="body1">
                  This is how many days the accelerator will run for after a customers first stamp. Default is set to 30 days.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="reward-expiry-entry"
                  label="Accelerator Period"
                  variant="outlined"
                  color="primary"
                  value={rewardExpiry}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ autoComplete: 'off' }}
                  onChange={(event) => {
                    const setAmount = event.target.value.replace(/\D+/g, '');
                    setRewardExpiry(setAmount);
                  }}
                />
              </Grid>
            </>
          ) : null}

          {type === 'custom' ? (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">Custom Reward</Typography>
                <Typography variant="body1">
                  As this is a custom campaign you can enter in any text here and it will appear as a claimable reward in the in-app
                  inbox, once claimed it will appear as a voucher in the wallet page.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="custom-reward"
                  label="Custom Reward"
                  variant="outlined"
                  color="primary"
                  value={scheme}
                  error={error && scheme === ''}
                  onChange={(event) => {
                    setScheme(event.target.value);
                    setVoucherText(event.target.value);
                  }}
                  InputProps={{
                    autoComplete: 'off',
                  }}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">Scheme</Typography>
                <Typography variant="body1">Select which Scheme this reward is tied too</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  InputLabelProps={{ shrink: true }}
                  id="scheme-select"
                  label="Scheme"
                  variant="outlined"
                  color="primary"
                  value={scheme}
                  error={error && scheme === ''}
                  onChange={(event) => {
                    setScheme(event.target.value);
                    let i = 0;
                    while (i < schemeOptions.length) {
                      if (schemeOptions[i].value === event.target.value) setVoucherText(schemeOptions[i].reward);
                      i += 1;
                    }
                  }}
                  InputProps={{
                    autoComplete: 'off',
                  }}
                >
                  <MenuItem key={0} value="" disabled>Select one of the below</MenuItem>
                  {schemeOptions.map((schemeOpt) => (
                    <MenuItem key={schemeOpt.value} value={schemeOpt.value}>{schemeOpt.display}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Typography variant="body2">Total amount of Rewards</Typography>
            <Typography variant="body1">
              This is the total amount of rewards this campaign will give out. If you wish to have unlimited rewards please tick the checkbox.
              If &apos;Unlimited Rewards&apos; is selected the textbox to the left will be disabled.
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="total-entry"
              label="Reward Total"
              variant="outlined"
              color="primary"
              value={totalAmount}
              error={error && !unlimitedRewards && totalAmount === ''}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'off' }}
              onChange={(event) => {
                const setAmount = event.target.value.replace(/\D+/g, '');
                setTotalAmount(setAmount);
              }}
              disabled={unlimitedRewards}
            />
          </Grid>

          <Grid item container xs={6} justifyContent="center">
            <FormControlLabel
              control={(
                <Checkbox
                  checked={unlimitedRewards}
                  onChange={() => {
                    setUnlimitedRewards(!unlimitedRewards);
                  }}
                />
              )}
              label="Unlimited Rewards"
            />
          </Grid>

          {type !== 'custom' && (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">Reward Amount Per Customer</Typography>
                <Typography variant="body1">
                  This is the amount of vouchers / stamps an individual customer will receive. E.g 2 Vouchers
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="customer-entry"
                  label="Customer Reward amount"
                  variant="outlined"
                  color="primary"
                  value={amountCustomer}
                  error={error && amountCustomer === ''}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ autoComplete: 'off' }}
                  onChange={(event) => {
                    const setAmount = event.target.value.replace(/\D+/g, '');
                    setAmountCustomer(setAmount);
                  }}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Campaign Settings</Typography></Divider>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Campaign Name</Typography>
            <Typography variant="body1">
              This is the name that will be used to refer to the campaign. This will appear on the businesses campaign page.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="reward-entry"
              label="Name"
              variant="outlined"
              color="primary"
              value={description}
              error={error && description === ''}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'off' }}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="body2">Start Date & Time of Campaign</Typography>
            <Typography variant="body1">
              This is the time in which the campaign will start. If you wish to start the campaign immediately then tick the box on the right.
            </Typography>
          </Grid>

          <Grid item container xs={6} justifyContent="center">
            <DateTimePicker
              value={startTime}
              onChange={(value) => {
                if (value !== null) setStartTime(value);
              }}
              disabled={startNow}
            />
          </Grid>

          <Grid item container xs={6} justifyContent="center">
            <FormControlLabel
              control={(
                <Checkbox
                  checked={startNow}
                  onChange={() => {
                    setStartNow(!startNow);
                  }}
                />
              )}
              label="Start Immediately"
            />
          </Grid>

          {type !== 'custom' && (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">End Date & Time of Campaign</Typography>
                <Typography variant="body1">
                  This will time in which the campaign will end. If you want the campaign to run indefinitely the tick the box on the right.
                </Typography>
              </Grid>
              <Grid item container xs={6} justifyContent="center">
                <DateTimePicker
                  value={endTime}
                  onChange={(value) => {
                    if (value !== null) setEndTime(value);
                  }}
                  slotProps={{
                    textField: {
                      error: error && ((endTime <= startTime || endTime <= now)),
                    },
                  }}
                  disabled={neverEnd}
                />
              </Grid>
              <Grid item container xs={6} justifyContent="center">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={neverEnd}
                      onChange={() => {
                        setNeverEnd(!neverEnd);
                      }}
                    />
                  )}
                  label="Run Indefinitely"
                />
              </Grid>
            </>
          )}

          {locationOptions.length > 0 ? (
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Select a Location</Typography>
                <Typography variant="body1">
                  If no location is selected, the campaign will work at all locations
                </Typography>
              </Grid>

              <Grid item container xs={12} alignContent="center">
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={selectedLocations.length === Object.keys(businessData.Locations).length}
                        onChange={() => {
                          if (selectedLocations.length === Object.keys(businessData.Locations).length) {
                            setSelectedLocations([]);
                          } else {
                            setSelectedLocations(Object.keys(businessData.Locations));
                          }
                        }}
                      />
                    )}
                    label="All Locations"
                  />

                  {locationOptions.map((loc) => (
                    <FormControlLabel
                      key={loc.value}
                      control={(
                        <Checkbox
                          checked={selectedLocations.includes(loc.value)}
                          onChange={() => {
                            const newLocations = selectedLocations;
                            const index = selectedLocations.indexOf(loc.value);
                            if (index === -1) newLocations.push(loc.value);
                            else {
                              newLocations.splice(index, 1);
                            }
                            setSelectedLocations([...newLocations]);
                          }}
                        />
                      )}
                      label={`${loc.display}`}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>
          ) : null}

          {type !== 'acquisition' ? (
            <>
              <Grid item xs={12} sx={{ marginTop: '20px' }}>
                <Divider><Typography variant="body2">Inbox Message Settings</Typography></Divider>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2">Message Title</Typography>
                <Typography variant="body1">
                  This is the title of the message which will be displayed in the in-app inbox.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="title-entry"
                  label="Message Title"
                  variant="outlined"
                  color="primary"
                  value={messageHeader}
                  error={error && messageHeader === ''}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ autoComplete: 'off' }}
                  onChange={(event) => {
                    setMessageHeader(event.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2">Message Body</Typography>
                <Typography variant="body1">
                  This is the main message content which will be displayed in the in-app inbox.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="body-entry"
                  label="Message Body"
                  variant="outlined"
                  color="primary"
                  value={messageBody}
                  error={error && messageBody === ''}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ autoComplete: 'off' }}
                  onChange={(event) => {
                    setMessageBody(event.target.value);
                  }}
                />
              </Grid>

            </>
          ) : (
            <>
              <Grid item xs={12} sx={{ marginTop: '20px' }}>
                <Divider><Typography variant="body2">Promotion Codes</Typography></Divider>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2">Acquisition Promotion Codes</Typography>
                <Typography variant="body1">
                  Acquisition campaigns are used through promotion codes. Please enter in how many promo codes you want and click &apos;generate&apos;.
                  these codes are to be used in third part promotions such as leaflets and flyers.
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="promo-entry"
                  label="Code Amount"
                  variant="outlined"
                  color="primary"
                  value={codeAmount}
                  error={error && (codeAmount === '' || promoCodes.length === 0)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ autoComplete: 'off' }}
                  onChange={(event) => {
                    const setAmount = event.target.value.replace(/\D+/g, '');
                    setCodeAmount(setAmount);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <StyledGenerate
                  variant="contained"
                  fullWidth
                  startIcon={<GiConfirmed />}
                  onClick={() => {
                    generateCodes();
                  }}
                >
                  <Typography variant="body2">GENERATE</Typography>
                </StyledGenerate>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2">Optional Promotion Code Labels</Typography>
                <Typography variant="body1">
                  Once the codes are generated you can give each code a &apos;label&apos;. This is to give context to differ the codes from one
                  another E.g &apos;Poster Code&apos; , &apos;Leaflet Code&apos; etc. These labels can be seen on the within the new business portal
                </Typography>
              </Grid>

              {promoCodes.length > 0 && promoCodes.map((code, index) => (
                <Grid item xs={6} key={code.value}>
                  <Typography variant="body2" sx={{ paddingBottom: '10px' }}>{`Code : ${code.value}`}</Typography>
                  <TextField
                    fullWidth
                    id="label-entry"
                    label={`${code.value}'s Label`}
                    variant="outlined"
                    color="primary"
                    value={code.label}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ autoComplete: 'off' }}
                    onChange={(event) => {
                      const label = event.target.value;
                      const newCode = promoCodes;
                      newCode[index] = { label, value: code.value };
                      setPromoCodes([...newCode]);
                    }}
                  />
                </Grid>
              ))}

            </>

          )}

          <Grid item xs={12} sx={{ marginTop: '20px' }}>
            <Divider><Typography variant="body2">Optional Settings</Typography></Divider>
          </Grid>

          {type !== 'acquisition' ? (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">Reward Expiry</Typography>
                <Typography variant="body1">
                  If this is not set the default will be 30 days. This is how many days they have to claim the reward and then how long they have to redeem it.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="reward-expiry-entry"
                  label="Reward Expiry"
                  variant="outlined"
                  color="primary"
                  value={rewardExpiry}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ autoComplete: 'off' }}
                  onChange={(event) => {
                    const setAmount = event.target.value.replace(/\D+/g, '');
                    setRewardExpiry(setAmount);
                  }}
                />
              </Grid>
            </>
          ) : null}

          <Grid item xs={12}>
            <Typography variant="body2">Email Address</Typography>
            <Typography variant="body1">
              This is the email that campaign updates will be sent to.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email-entry"
              label="Email Address"
              variant="outlined"
              color="primary"
              value={emailAddress}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'off' }}
              onChange={(event) => {
                setEmailAddress(event.target.value);
              }}
            />
          </Grid>

        </Grid>
      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={() => handleClose()}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>
        <StyledConfirm
          variant="contained"
          fullWidth
          startIcon={<GiConfirmed />}
          onClick={() => {
            const pass = checkInputs();
            if (pass) setOpenConfirm(true);
            else {
              setErrorMessage('Missing options! please check the required inputs');
              setError(true);
              setOpenError(true);
            }
          }}
        >
          <Typography variant="body2">CONFIRM</Typography>
        </StyledConfirm>
      </DialogActions>

      <ConfirmDialog
        open={openConfirm}
        handleCancel={() => setOpenConfirm(false)}
        handleConfirm={() => {
          if (type === 'custom') createCustomNotification();
          else createCampaign();
        }}
        dialogTitle={isCreate ? `Create Campaign for ${businessId}` : `Save Changes to Campaign for ${businessId}`}
        dialogText={isCreate
          ? 'Are you sure you wish to create this new campaign ?'
          : 'Are you sure you wish to save these changes to this existing campaign ?'}
      />

      {openAlert && (
        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          dialogTitle="Success"
          dialogText={`Campaign for ${businessData['Basic Fields']['Chain Name']} has been successfully created!`}
          handleOk={() => {
            setOpenAlert(false);
            setOpen(false);
          }}
        />
      )}

      {openError && (
        <ErrorDialog
          dialogTitle="Error"
          dialogText={errorMessage}
          open={openError}
          handleClose={() => {
            setOpenError(false);
            setErrorMessage('');
          }}
        />
      )}

    </Dialog>
  );
}

export default CampaignCreate;
