import React, { useState } from 'react';

import {
  TableRow,
  TableCell,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { AiFillDelete } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';

import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';

import AdminService from '../../../Services/AdminService';

const StyledBodyCell = styled(TableCell, {})({
  textAlign: 'center',
});

const StyledDeleteButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.main,
  '&:hover': {
    color: theme.palette.warning.main,
  },
}));

const StyledEditButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.main,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

type Props = {
  fetchAdminUsers: () => void,
  handleEdit: (
    email: string,
    type: string,
    role: string,
    uid: string,
  ) => void,
  handleBusinessEdit?: (
    email: string,
    role: string,
    businessId: string,
    locationAccess: string,
    uid: string,
  ) => void,
  rowIndex: number,
  rowItems: string[];
  isAdmin: boolean,
};

function SearchTableRow(props: Props) {
  const {
    fetchAdminUsers,
    handleEdit,
    handleBusinessEdit,
    rowIndex,
    rowItems,
    isAdmin,
  } = props;

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorDialogTitle, setErrorDialogTitle] = useState<string>('');
  const [errorDialogMessage, setErrorDialogMessage] = useState<string>('');

  const handleErrorDialog = (title:string, message:string) => {
    setErrorDialogTitle(title);
    setErrorDialogMessage(message);
    setOpenErrorDialog(true);
  };

  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
    setErrorDialogTitle('');
    setErrorDialogMessage('');
  };

  const deleteRow = async () => {
    try {
      await AdminService.deleteAdminUserById(isAdmin ? rowItems[3] : rowItems[4], 'admin');
      await fetchAdminUsers();
      setOpenDelete(false);
    } catch (e:any) {
      handleErrorDialog('Cannot delete user', e.message);
    }
  };

  return (
    <>
      <TableRow key={rowIndex}>
        {rowItems.map((cellData, cellIndex) => (
          <StyledBodyCell key={cellIndex}>
            <Typography variant="body1">{cellData}</Typography>
          </StyledBodyCell>
        ))}
        <StyledBodyCell key={4}>
          <StyledEditButton
            size="small"
            onClick={() => {
              if (!isAdmin && handleBusinessEdit) {
                handleBusinessEdit(rowItems[0], rowItems[1], rowItems[2], rowItems[3], rowItems[4]);
              } else {
                handleEdit(rowItems[0], rowItems[1], rowItems[2], rowItems[3]);
              }
            }}
          >
            <FaEdit />
          </StyledEditButton>
        </StyledBodyCell>
        <StyledBodyCell key={5}>
          <StyledDeleteButton size="small" onClick={() => setOpenDelete(true)}>
            <AiFillDelete />
          </StyledDeleteButton>
        </StyledBodyCell>
      </TableRow>

      <ConfirmDialog
        dialogTitle="Delete User"
        dialogText={isAdmin ? (`Are you sure you want to permanently delete this user?\n
          Email Address : ${rowItems[0]}\nUser Type : ${rowItems[1]}
          User Role : ${rowItems[2]}\nUser ID : ${rowItems[3]}`
        ) : (
          `Are you sure you want to permanently delete this user?\n
            Email Address : ${rowItems[0]}\nBusiness ID : ${rowItems[2]}
            Business Tier : ${rowItems[1]}\nLocation Access : ${rowItems[3]}
            User ID : ${rowItems[4]}`
        )}
        open={openDelete}
        handleCancel={() => setOpenDelete(false)}
        handleConfirm={deleteRow}
        isDelete
      />

      <ErrorDialog
        open={openErrorDialog}
        dialogTitle={errorDialogTitle}
        dialogText={errorDialogMessage}
        handleClose={handleErrorDialogClose}
      />
    </>
  );
}

export default SearchTableRow;
