/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  DialogActions,
  DialogContent,
  Divider,
  Button,
  Dialog,
  Grid,
  Typography,
  TextField,
} from '@mui/material';
import { styled } from '@mui/system';
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import OpeningHourSelector, { Times } from '../../../Components/BasicComponents/OpeningHourSelector';
import AutoCompleteAddress from '../../../Components/BasicComponents/AutoCompleteAddress';
import GoogleMapContainer from '../../../Components/BasicComponents/GoogleMapContainer';

import LocationService, { Location } from '../../../Services/LocationService';
import DiscoveryService from '../../../Services/DiscoveryService';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '25px',
  paddingBottom: '10px',
});

const StyledBorder = styled(Divider, {})({
  marginTop: '15px',
  marginBottom: '15px',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

type Props = {
  loadBusinessDetails: (id: string) => void,
  partnerId: string,
  locationId: string,
  open: boolean,
  setOpen: (toggle:boolean) => void,
  isCreate: boolean,
  locationData: {
    Address: string,
    HoursList: {
      [key:string]: string,
    },
    Lat: number,
    Long: number,
    'Short Address': string,
    'Google Place ID'?: string,
  },
};

function LocationEdit(props: Props) {
  const {
    loadBusinessDetails,
    partnerId,
    locationId,
    open,
    setOpen,
    locationData,
    isCreate,
  } = props;

  const defaultDates: Times = {
    Monday: {
      openingHour: '00',
      openingMinute: '00',
      closingHour: '00',
      closingMinute: '00',
    },
    Tuesday: {
      openingHour: '00',
      openingMinute: '00',
      closingHour: '00',
      closingMinute: '00',
    },
    Wednesday: {
      openingHour: '00',
      openingMinute: '00',
      closingHour: '00',
      closingMinute: '00',
    },
    Thursday: {
      openingHour: '00',
      openingMinute: '00',
      closingHour: '00',
      closingMinute: '00',
    },
    Friday: {
      openingHour: '00',
      openingMinute: '00',
      closingHour: '00',
      closingMinute: '00',
    },
    Saturday: {
      openingHour: '00',
      openingMinute: '00',
      closingHour: '00',
      closingMinute: '00',
    },
    Sunday: {
      openingHour: '00',
      openingMinute: '00',
      closingHour: '00',
      closingMinute: '00',
    },
  };

  const [address, setAddress] = useState<string>('');
  const [shortAddress, setShortAddress] = useState('');
  const [coordinates, setCoordinates] = useState<{ lat: number, lng: number }>({ lat: 0, lng: 0 });
  const [googlePlaceId, setGooglePlaceId] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newTimes, setNewTimes] = useState<Times>(defaultDates);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [timeError, setTimeError] = useState<boolean>(false);

  useEffect(() => {
    if (!isCreate && locationData) {
      if (locationData.HoursList) {
        let existingTimes: Times = {};
        const dayKeys = Object.keys(defaultDates);
        dayKeys.forEach((day) => {
          let openingHour = '00';
          let openingMinute = '00';
          let closingHour = '00';
          let closingMinute = '00';
          if (!locationData.HoursList[day].toLocaleLowerCase().includes('closed')) {
            openingHour = locationData.HoursList[day].split('-')[0].split(':')[0] === '0'
              ? '00'
              : locationData.HoursList[day].split('-')[0].split(':')[0];
            openingMinute = locationData.HoursList[day].split('-')[0].split(':')[1] === '59'
              ? '00'
              : locationData.HoursList[day].split('-')[0].split(':')[1];
            closingHour = locationData.HoursList[day].split('-')[1].split(':')[0] === '0'
              ? '00'
              : locationData.HoursList[day].split('-')[1].split(':')[0];
            closingMinute = locationData.HoursList[day].split('-')[1].split(':')[1] === '59'
              ? '00'
              : locationData.HoursList[day].split('-')[1].split(':')[1];

            if (locationData.HoursList[day].split('-')[0] === '23:59'
          || locationData.HoursList[day].split('-')[0] === '0:00') {
              openingHour = '00';
              openingMinute = '00';
            }

            if (locationData.HoursList[day].split('-')[1] === '23:59'
          || locationData.HoursList[day].split('-')[1] === '0:00') {
              closingHour = '00';
              closingMinute = '00';
            }
          }

          const dayTimes = {
            [day]: {
              openingHour,
              openingMinute,
              closingHour,
              closingMinute,
            },
          };

          existingTimes = {
            ...existingTimes,
            ...dayTimes,
          };
        });
        setNewTimes(existingTimes);
      }
      setAddress(locationData.Address);
      setShortAddress(locationData['Short Address']);
      setGooglePlaceId(locationData['Google Place ID']!);
      setCoordinates({ lat: locationData.Lat, lng: locationData.Long });
    }
  }, [locationData, open]);

  const handleClose = () => {
    setOpen(false);
    setNewTimes(defaultDates);
  };

  const throwError = (error:string) => {
    setOpenConfirm(false);
    setOpenErrorDialog(true);
    setErrorText(error);
  };

  const setTimes = (selectedTimes: Times) => {
    const dayKeys = Object.keys(selectedTimes);
    let toggle = false;
    dayKeys.forEach((day) => {
      if (selectedTimes[day].openingHour.length < 2
        || selectedTimes[day].openingMinute.length < 2
        || selectedTimes[day].closingHour.length < 2
        || selectedTimes[day].closingMinute.length < 2
      ) toggle = true;
    });
    setTimeError(toggle);
    setNewTimes(selectedTimes);
  };

  const confirmInputs = (): boolean => {
    if (address === '') {
      throwError('Address is required.');
      return false;
    }
    if (shortAddress === '') {
      throwError('Short Address is required.');
      return false;
    }
    if (googlePlaceId === '') {
      throwError('Google Place ID is required.');
      return false;
    }
    if (coordinates.lat === 0 || coordinates.lng === 0) {
      throwError('Latitude & Longitude are required.');
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    const save = confirmInputs();
    if (save) {
      const newHourList = {
        Monday: `${newTimes.Monday.openingHour}:${newTimes.Monday.openingMinute}-${newTimes.Monday.closingHour}:${newTimes.Monday.closingMinute}`,
        Tuesday: `${newTimes.Tuesday.openingHour}:${newTimes.Tuesday.openingMinute}-${newTimes.Tuesday.closingHour}:${newTimes.Tuesday.closingMinute}`,
        Wednesday: `${newTimes.Wednesday.openingHour}:${newTimes.Wednesday.openingMinute}-${newTimes.Wednesday.closingHour}:${newTimes.Wednesday.closingMinute}`,
        Thursday: `${newTimes.Thursday.openingHour}:${newTimes.Thursday.openingMinute}-${newTimes.Thursday.closingHour}:${newTimes.Thursday.closingMinute}`,
        Friday: `${newTimes.Friday.openingHour}:${newTimes.Friday.openingMinute}-${newTimes.Friday.closingHour}:${newTimes.Friday.closingMinute}`,
        Saturday: `${newTimes.Saturday.openingHour}:${newTimes.Saturday.openingMinute}-${newTimes.Saturday.closingHour}:${newTimes.Saturday.closingMinute}`,
        Sunday: `${newTimes.Sunday.openingHour}:${newTimes.Sunday.openingMinute}-${newTimes.Sunday.closingHour}:${newTimes.Sunday.closingMinute}`,
      };

      const newLocation: Location = {
        Address: address || '',
        'Short Address': shortAddress || '',
        'Google Place ID': googlePlaceId || '',
        HoursList: newHourList,
        Lat: coordinates.lat,
        Long: coordinates.lng,
      };

      if (isCreate) {
        try {
          await LocationService.createLocation(partnerId, newLocation);
          await DiscoveryService.resetCache();
          loadBusinessDetails(partnerId);
          handleClose();
        } catch (e:any) {
          throwError(e);
        }
      } else {
        try {
          await LocationService.updateLocation(partnerId, locationId, newLocation);
          await DiscoveryService.resetCache();
          loadBusinessDetails(partnerId);
          handleClose();
        } catch (e:any) {
          throwError(e);
        }
      }
    }
  };

  const handleClosed = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleClose();
  };

  return (
    <Dialog
      aria-label="confirm-dialog"
      onClose={(event, reason) => handleClosed(event, reason)}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ zIndex: '3000' }}
    >
      {isCreate ? (
        <StyledTitle variant="h1">Create New Location</StyledTitle>
      ) : (
        <StyledTitle variant="h1">{`Edit Location ${locationId}`}</StyledTitle>
      )}

      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <StyledBorder light><Typography variant="body2">New Address Entry</Typography></StyledBorder>
          </Grid>
          <Grid item xs={12}>
            <AutoCompleteAddress
              setCoordinates={setCoordinates}
              setAddress={setAddress}
              setShortAddress={setShortAddress}
              setGooglePlaceId={setGooglePlaceId}
            />
          </Grid>

          {address !== '' ? (
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <StyledBorder light><Typography variant="body2">Location Details</Typography></StyledBorder>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="long-address"
                  label="Long Address"
                  variant="outlined"
                  color="primary"
                  value={address}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => {
                    setAddress(event.target.value);
                  }}
                  InputProps={{
                    autoComplete: 'off',
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="short-address"
                  label="Short Address"
                  variant="outlined"
                  color="primary"
                  value={shortAddress}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => {
                    setShortAddress(event.target.value);
                  }}
                  InputProps={{
                    autoComplete: 'off',
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2">Google Place ID :</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{googlePlaceId}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2">Latitude & Longitude :</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{`${coordinates.lat}, ${coordinates.lng}`}</Typography>
              </Grid>
            </Grid>
          ) : null}

          {coordinates.lat !== 0 && coordinates.lng !== 0 ? (
            <Grid item container>
              <GoogleMapContainer
                lat={coordinates.lat}
                lng={coordinates.lng}
                zoom={15}
                enableMapOptions={false}
                isDraggable
                setCoordinates={setCoordinates}
              />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <StyledBorder light><Typography variant="body2">Location Opening Times</Typography></StyledBorder>
          </Grid>

          <Grid item xs={12} container justifyContent="center" alignContent="center" style={{ marginBottom: '30px' }}>
            <Typography variant="body1">
              Please note, any day set to the time of 00:00 - 00:00 will be regarded as closed for that entire day!
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ margin: '10px' }}>
            <OpeningHourSelector openingTimes={newTimes} setOpeningTimes={setTimes} />
          </Grid>
          {timeError ? (
            <Grid item container xs={12} justifyContent="center" alignContent="center">
              <Typography variant="body1" color="red">All Times must be in the 24 hour format</Typography>
            </Grid>
          ) : null}

        </Grid>
      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={handleClose}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>
        <StyledConfirm
          variant="contained"
          fullWidth
          disabled={timeError || shortAddress === ''}
          startIcon={<GiConfirmed />}
          onClick={() => {
            setOpenConfirm(true);
          }}
        >
          <Typography variant="body2">CONFIRM</Typography>
        </StyledConfirm>
      </DialogActions>

      <ConfirmDialog
        open={openConfirm}
        handleCancel={() => {
          setOpenConfirm(false);
        }}
        handleConfirm={() => {
          handleSave();
        }}
        dialogTitle="Save Changes"
        dialogText="Are you sure you want to save changes?"
      />

      <ErrorDialog
        open={openErrorDialog}
        dialogTitle=""
        dialogText={errorText}
        handleClose={() => {
          setOpenErrorDialog(false);
          setErrorText('');
        }}
      />

    </Dialog>
  );
}

export default LocationEdit;
