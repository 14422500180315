/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { FaUserLock } from 'react-icons/fa';
import EntryDialog from '../../../Components/BasicComponents/EntryDialog';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';

import AdminService from '../../../Services/AdminService';
import UserService, { EmailSearch } from '../../../Services/UserService';
import AuthService from '../../../Services/AuthService';
import BusinessService from '../../../Services/BusinessService';

type Props = {
  fetchAdminUsers: () => void;
  openCreateFlow: boolean,
  setOpenCreateFlow: (toggle: boolean) => void;
};

function CreateNewUser(props: Props) {
  const {
    fetchAdminUsers,
    openCreateFlow,
    setOpenCreateFlow,
  } = props;

  const [userId, setUserId] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [openPassword, setOpenPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [openDisplayName, setOpenDisplayName] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [openType, setOpenType] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [userTypeOptions, setUserTypeOptions] = useState<string[]>([]);
  const [userTypeText, setUserTypeText] = useState<string>('');
  const [openBusiness, setOpenBusiness] = useState<boolean>(false);
  const [partnerId, setPartnerId] = useState<string>('');
  const [openRole, setOpenRole] = useState<boolean>(false);
  const [role, setRole] = useState<string>('');
  const [userRoleOptions, setUserRoleOptions] = useState<string[]>([]);
  const [userRoleText, setUserRoleText] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorDialogTitle, setErrorDialogTitle] = useState<string>('');
  const [errorDialogMessage, setErrorDialogMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<string>('');

  const handleCancel = () => {
    setUserId('');
    setUserEmail('');
    setType('');
    setRole('');
    setPassword('');
    setName('');
    setPartnerId('');
    setOpenBusiness(false);
    setOpenCreateFlow(false);
    setOpenType(false);
    setOpenRole(false);
    setOpenConfirm(false);
    setOpenPassword(false);
    setOpenDisplayName(false);
    setLoading(false);
    setConfirmText('');
  };

  const handleErrorDialog = (title:string, message:string) => {
    setErrorDialogTitle(title);
    setErrorDialogMessage(message);
    setOpenErrorDialog(true);
  };

  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
    setErrorDialogTitle('');
    setErrorDialogMessage('');
  };

  const parseAllUserRoles = async () => {
    try {
      const roles = await AdminService.getRolesByUserType(type);
      const options: string[] = [];
      let typeText: string = '';
      roles.forEach((r) => {
        options.push(r.role_id);
        typeText += `\n${r.role_id}: ${r.description}\n`;
      });
      setUserRoleText(typeText);
      setUserRoleOptions(options);
    } catch (e:any) {
      handleErrorDialog('Cannot fetch user permissions', e.message);
    }
  };

  const parseAllUserTypes = async () => {
    try {
      const types = await AdminService.getUserTypes();
      const options: string[] = [];
      let typeText: string = '';
      types.forEach((t) => {
        options.push(t.user_types);
        typeText += `\n${t.user_types}: ${t.description}\n`;
      });
      setUserTypeText(typeText);
      setUserTypeOptions(options);
    } catch (e:any) {
      handleErrorDialog('Cannot fetch user types', e.message);
    }
  };

  const handleSave = async () => {
    try {
      if (partnerId === '') {
        await AdminService.createUser(userId, userEmail, type, role);
      } else {
        await AdminService.createUser(userId, userEmail, type, role, partnerId);
      }
    } catch (e:any) {
      handleErrorDialog('Cannot create user', e.message);
    }
    handleCancel();
    fetchAdminUsers();
  };

  const createAccount = async () => {
    setLoading(true);
    try {
      const newUser = await AuthService.createAccount(userEmail, password, name);
      setUserId(newUser.uid);
    } catch (e:any) {
      setErrorDialogMessage(e.messgae);
      setOpenErrorDialog(true);
    }
    setLoading(false);
  };

  const checkExistingUser = async (uid: string) => {
    setLoading(true);
    try {
      const user = await AdminService.getAdminUserById(uid);
      if (user) {
        handleErrorDialog(
          'User already exists',
          'That email is already in use, please use the edit feature on the table below',
        );
      }
    } catch (e:any) {
      setOpenCreateFlow(false);
      setOpenType(true);
    }
    setLoading(false);
  };

  const confirmEmail = async () => {
    setLoading(true);
    try {
      const user:EmailSearch = await UserService.getUserByEmail(userEmail);
      setUserId(user.userId);
      checkExistingUser(user.userId);
    } catch (e:any) {
      setOpenCreateFlow(false);
      setOpenPassword(true);
    }
    setLoading(false);
  };

  const handleTypeConfirm = async () => {
    await parseAllUserRoles();
    setOpenType(false);
    if (type === 'business_user') {
      setOpenBusiness(true);
    } else {
      setOpenRole(true);
    }
  };

  const checkBusiness = async () => {
    setLoading(true);
    try {
      await BusinessService.getBusiness(partnerId);
      setOpenBusiness(false);
      setOpenRole(true);
    } catch (e:any) {
      handleErrorDialog(
        'Business does not Exist',
        `The business id ${partnerId} does not belong to any business , please enter in a valid business ID.`,
      );
    }
    setLoading(false);
  };

  const getConfirmText = () => {
    let text = `User Email: ${userEmail}
    User ID: ${userId}
    User Type: ${type}
    User Role: ${role}\n`;

    if (partnerId !== '') {
      text += `Business ID: ${partnerId}`;
    }

    setConfirmText(text);
  };

  const handleRoleConfirm = () => {
    getConfirmText();
    setOpenRole(false);
    setOpenConfirm(true);
  };

  useEffect(() => {
    parseAllUserTypes();
  }, []);

  return (
    <>
      <EntryDialog
        disableEntryFormat
        dialogTitle="User Email"
        dialogText="Please enter in the users email address"
        handleCancel={handleCancel}
        handleConfirm={confirmEmail}
        open={openCreateFlow}
        value={userEmail}
        setValue={setUserEmail}
        valueIcon={<FaUserLock />}
        loading={loading}
      />

      <EntryDialog
        disableEntryFormat
        dialogTitle="New Account Password"
        // eslint-disable-next-line max-len
        dialogText={`This email is not registered! This will create a new account under the email ${userEmail}. Please set a default password, this can be changed at a later date.`}
        handleCancel={handleCancel}
        handleConfirm={() => {
          setOpenPassword(false);
          setOpenDisplayName(true);
        }}
        open={openPassword}
        value={password}
        setValue={setPassword}
        valueIcon={<FaUserLock />}
        loading={loading}
      />

      <EntryDialog
        disableEntryFormat
        dialogTitle="Display Name"
        dialogText="Please enter a display name for this new account"
        handleCancel={handleCancel}
        handleConfirm={async () => {
          await createAccount();
          setOpenType(true);
          setOpenDisplayName(false);
        }}
        open={openDisplayName}
        value={name}
        setValue={setName}
        valueIcon={<FaUserLock />}
        loading={loading}
      />

      <EntryDialog
        dialogTitle="Please select a user type"
        dialogText={userTypeText}
        handleCancel={handleCancel}
        handleConfirm={handleTypeConfirm}
        open={openType}
        value={type}
        setValue={setType}
        selectOptions={userTypeOptions}
        valueIcon={<FaUserLock />}
        loading={loading}
      />

      <EntryDialog
        dialogTitle="Business ID"
        dialogText="Please enter the business ID"
        handleCancel={handleCancel}
        handleConfirm={checkBusiness}
        open={openBusiness}
        value={partnerId}
        setValue={setPartnerId}
        valueIcon={<FaUserLock />}
        loading={loading}
      />

      <EntryDialog
        dialogTitle="Please select a user role"
        dialogText={userRoleText}
        handleCancel={handleCancel}
        handleConfirm={handleRoleConfirm}
        open={openRole}
        value={role}
        setValue={setRole}
        selectOptions={userRoleOptions}
        valueIcon={<FaUserLock />}
        loading={loading}
      />

      <ConfirmDialog
        open={openConfirm}
        dialogTitle="Are you sure you want to save this user?"
        dialogText={confirmText}
        handleCancel={handleCancel}
        handleConfirm={handleSave}
      />

      <ErrorDialog
        open={openErrorDialog}
        dialogTitle={errorDialogTitle}
        dialogText={errorDialogMessage}
        handleClose={handleErrorDialogClose}
      />
    </>
  );
}

export default CreateNewUser;
