import React, { useState } from 'react';

import {
  TableRow,
  TableCell,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { AiFillDelete } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';

import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';

import AdminService from '../../../Services/AdminService';

const StyledBodyCell = styled(TableCell, {})({
  textAlign: 'center',
});

const StyledDeleteButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.main,
  '&:hover': {
    color: theme.palette.warning.main,
  },
}));

const StyledEditButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.main,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

export type BusinessRowData = {
  businessId: string,
  email: string,
  tier: string,
  locationAccess: string,
  userId: string,
};

type Props = {
  fetchAdminUsers: () => void,
  handleBusinessEdit: (
    email: string,
    role: string,
    businessId: string,
    locationAccess: string,
    uid: string,
  ) => void,
  rowIndex: number,
  rowItems: BusinessRowData;
};

function BusinessRow(props: Props) {
  const {
    fetchAdminUsers,
    handleBusinessEdit,
    rowIndex,
    rowItems,
  } = props;

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorDialogTitle, setErrorDialogTitle] = useState<string>('');
  const [errorDialogMessage, setErrorDialogMessage] = useState<string>('');

  const handleErrorDialog = (title:string, message:string) => {
    setErrorDialogTitle(title);
    setErrorDialogMessage(message);
    setOpenErrorDialog(true);
  };

  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
    setErrorDialogTitle('');
    setErrorDialogMessage('');
  };

  const deleteRow = async () => {
    try {
      await AdminService.deleteAdminUserById(rowItems.userId, 'business');
      await fetchAdminUsers();
      setOpenDelete(false);
    } catch (e:any) {
      handleErrorDialog('Cannot delete user', e.message);
    }
  };

  return (
    <>
      <TableRow key={rowIndex}>
        {Object.values(rowItems).map((cellData, cellIndex) => (
          <StyledBodyCell key={cellIndex}>
            <Typography variant="body1">{cellData}</Typography>
          </StyledBodyCell>
        ))}
        <StyledBodyCell key={4}>
          <StyledEditButton
            size="small"
            onClick={() => {
              handleBusinessEdit(
                rowItems.email,
                rowItems.tier,
                rowItems.businessId,
                rowItems.locationAccess,
                rowItems.userId,
              );
            }}
          >
            <FaEdit />
          </StyledEditButton>
        </StyledBodyCell>
        <StyledBodyCell key={5}>
          <StyledDeleteButton size="small" onClick={() => setOpenDelete(true)}>
            <AiFillDelete />
          </StyledDeleteButton>
        </StyledBodyCell>
      </TableRow>

      <ConfirmDialog
        dialogTitle="Delete User"
        dialogText={
          `Are you sure you want to permanently delete this user?\n
            Email Address : ${rowItems.email}\nBusiness ID : ${rowItems.businessId}
            Business Tier : ${rowItems.tier}\nLocation Access : ${rowItems.locationAccess}
            User ID : ${rowItems.userId}`
        }
        open={openDelete}
        handleCancel={() => setOpenDelete(false)}
        handleConfirm={deleteRow}
        isDelete
      />

      <ErrorDialog
        open={openErrorDialog}
        dialogTitle={errorDialogTitle}
        dialogText={errorDialogMessage}
        handleClose={handleErrorDialogClose}
      />
    </>
  );
}

export default BusinessRow;
